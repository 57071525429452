body {
    scroll-behavior: smooth;
}

[data-title] {
	font-size: 18px;
	position: relative;
	cursor: help;
    
}
[data-ltitle] {
	font-size: 18px;
	position: relative;
	cursor: help;
}
[data-mtitle] {
	font-size: 18px;
	position: relative;
	cursor: help;
}
[data-rtitle] {
	font-size: 18px;
	position: relative;
	cursor: help;    
}
	
[data-title]:hover::before {
	content: attr(data-title);
    min-width: 105px;
	position: absolute;
	bottom: -46px;
    left: -50%;
	padding: 10px;
	border-radius: 10px;
	background: var(--bs-dark);	
	color: var(--bs-light);
	font-size: 14px;
	white-space: nowrap;
    transform: translateX(27%);
    z-index: 1;
}
[data-ltitle]:hover::before {
	content: attr(data-ltitle);
	position: absolute;
    min-width: 105px;
	bottom: -46px;    
	padding: 10px;
	border-radius: 10px;
	background: var(--bs-dark);	
	color: var(--bs-light);
	font-size: 14px;
	white-space: nowrap;
    z-index: 1;
}
[data-mtitle]:hover::before {
	content: attr(data-ltitle);
	position: absolute;
	bottom: -46px;    
	padding: 10px;
	border-radius: 10px;
	background: var(--bs-dark);	
	color: var(--bs-light);
	font-size: 14px;
	white-space: nowrap;
    z-index: 1;
}
[data-rtitle]:hover::before {
	content: attr(data-rtitle);
	position: absolute;
    min-width: 105px;
    left: 50%;
	bottom: -46px;    
	padding: 10px;
	border-radius: 10px;
	background: var(--bs-dark);	
	color: var(--bs-light);
	font-size: 14px;
	white-space: nowrap;
    z-index: 1;
    transform: translateX(-50%);
    text-align: center;
}

[data-title]:hover::after {
	content: '';
	position: absolute;
	bottom: -6px;
	left: 18px;
	border: 8px solid transparent;  
	border-bottom: 8px solid var(--bs-dark);
}
[data-ltitle]:hover::after {
	content: '';
	position: absolute;
	bottom: -6px;
	left: 25%;
	border: 8px solid transparent;
	border-bottom: 8px solid var(--bs-dark);
    transform: translateX(-25%);
}
[data-mtitle]:hover::after {
	content: '';
	position: absolute;
	bottom: -6px;
	left: 18px;
	border: 8px solid transparent;  
	border-bottom: 8px solid var(--bs-dark);
}
[data-rtitle]:hover::after {
	content: '';
	position: absolute;
	bottom: -6px;
	left: 50%;
	border: 8px solid transparent;  
    transform: translateX(-50%);
	border-bottom: 8px solid var(--bs-dark);
}

legend {
    float: none;
    width: auto;
}

#topbar {
	z-index: 10;
}

.container {
	max-width: 750px;
}

#quickViewLineChart {
	max-height: 500px;
}

.h-100px {
	height: 100px;
}

.h-50px {
	height: 50px;
}

.refresh {
    	-webkit-animation-name: spin;
    	-webkit-animation-duration: 500ms;
    	-webkit-animation-iteration-count: infinite;
    	-webkit-animation-timing-function: linear;
    	-moz-animation-name: spin;
    	-moz-animation-duration: 500ms;
    	-moz-animation-iteration-count: infinite;
    	-moz-animation-timing-function: linear;
    	-ms-animation-name: spin;
    	-ms-animation-duration: 500ms;
    	-ms-animation-iteration-count: infinite;
    	-ms-animation-timing-function: linear;
    
    	animation-name: spin;
    	animation-duration: 500ms;
    	animation-iteration-count: infinite;
    	animation-timing-function: linear;
}

.collapsed i {
        transform: rotate(0deg);
}

i {
        transform: rotate(180deg);
}

.icon {
        transform: rotate(0deg) !important;
}

#accordion_ReportWizard {
    position: sticky;
    z-index: 2;
    top: 54px;
    /* width: 100%; */
    border-radius: 0;
    margin: 0 auto !important;
}
i.bi-filetype-pdf {
    position: relative;
    transform: scale(1);
}
i.bi-filetype-pdf:active {
    color: var(--bs-success);
    width: 10px;
    height: 10px;
}

.quick_view div > span {
    color: #AA4A44;
    font-weight: 700;
}
.quick_view div > .green {
    color: green;
    font-weight: 600;
}

.value-field {
    position: relative;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

.msg_banner {
    position: relative;
    top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    opacity: 0;
    margin: 0 auto;    
    padding: .5em;
    border-radius: 5px;
    border: 1px solid green;
    transition: all .3s ease-in-out;
}
.msg_banner h1 {
    font-size: 1rem;
    margin: 0;
    padding: 0;
}
.msg_banner.success {
    background-color: rgba(18, 180, 18, 0.445);
}
.msg_banner.showing {
    top: 0px;
    opacity: 1;
}


/**************/
/* ANIMATIONS */
/**************/
@-ms-keyframes spin {
    	from { -ms-transform: rotate(0deg); }
    	to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
    	from { -moz-transform: rotate(0deg); }
    	to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    	from { -webkit-transform: rotate(0deg); }
    	to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    	from {
        	transform:rotate(0deg);
    	}
    	to {
        	transform:rotate(360deg);
	}
}
